import React from 'react'
import Contact from '../components/Contact'

export default function ContactUs() {
  return (
    <div>
    <div class="container-fluid bg-primary py-5 mb-5 hero-header">
        <div class="container py-5">
            <div class="row justify-content-center py-5">
                <div class="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                    <h1 class="display-3 text-white animated slideInDown">Contact Us</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Pages</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">Contact</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
        <div className="booking p-5">
            <div className="row g-5 align-items-center">
                <div className="col-md-6 text-white">
                    <h6 className="text-white text-uppercase">Inquiry</h6>
                    <h1 className="text-white mb-4">Contact Us</h1>
                    <p className="mb-4">Thank you for your interest in mrcreator.co.in Please fill out the following enquiry form, and we will get back to you as soon as possible.</p>
                    {/* <!-- <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p> */}
                    <a className="btn btn-outline-light py-3 px-5 mt-2" href="">Read More</a>
                </div>
                <Contact/>
            </div>
        </div>
    </div>
    </div>
    </div>
  )
}
