import { BrowserRouter } from 'react-router-dom';
import Header from './frontend/website/layouts/Header';
import Footer from './frontend/website/layouts/Footer';
import Navbar from './frontend/website/layouts/Navbar';
import FrontendRoute from './routes/FrontendRoute';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header/>
        <Navbar/>
            <FrontendRoute/>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
