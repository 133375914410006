import React from 'react';

export default function Package() {
  return (
    <div>
    <div class="container-fluid bg-primary py-5 mb-5 hero-header">
        <div class="container py-5">
            <div class="row justify-content-center py-5">
                <div class="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                    <h1 class="display-3 text-white animated slideInDown">Packages</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Pages</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">Packages</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div className="container-xxl py-5">
    <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">Technology</h6>
            <h1 className="mb-5">Awesome Technology</h1>
        </div>
        <div className="row g-4 justify-content-center">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="package-item">
                    <div className="overflow-hidden">
                        <img className="img-fluid" src="../frontend/img/php-image.jpeg" alt=""/>
                    </div>
                    <div className="d-flex border-bottom">
                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-map-marker-alt text-primary me-2"></i>PHP</small>
                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt text-primary me-2"></i>LARAVEL</small>
                        <small className="flex-fill text-center py-2"><i className="fa fa-user text-primary me-2"></i>CI</small>
                    </div>
                    <div className="text-center p-4">
                        {/* <!-- <h3 className="mb-0">$149.00</h3> --> */}
                        <div className="mb-3">
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                        </div>
                        <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam eos</p>
                        <div className="d-flex justify-content-center mb-2">
                            <a href="#" className="btn btn-sm btn-primary px-3 border-end">Read More</a>
                            <a href="#" className="btn btn-sm btn-primary px-3"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="package-item">
                    <div className="overflow-hidden">
                        <img className="img-fluid" src="../frontend/img/python.jpg" alt=""/>
                    </div>
                    <div className="d-flex border-bottom">
                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-map-marker-alt text-primary me-2"></i>PYTHON</small>
                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt text-primary me-2"></i>DJANGO</small>
                        <small className="flex-fill text-center py-2"><i className="fa fa-user text-primary me-2"></i>FLASH</small>
                    </div>
                    <div className="text-center p-4">
                        {/* <!-- <h3 className="mb-0">$139.00</h3> --> */}
                        <div className="mb-3">
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                        </div>
                        <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam eos</p>
                        <div className="d-flex justify-content-center mb-2">
                            <a href="#" className="btn btn-sm btn-primary px-3 border-end">Read More</a>
                            <a href="#" className="btn btn-sm btn-primary px-3"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="package-item">
                    <div className="overflow-hidden">
                        <img className="img-fluid" src="../frontend/img/React.js.jpeg" alt=""/>
                    </div>
                    <div className="d-flex border-bottom">
                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-map-marker-alt text-primary me-2"></i>JAVASCRIPT</small>
                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt text-primary me-2"></i>JQUERY</small>
                        <small className="flex-fill text-center py-2"><i className="fa fa-user text-primary me-2"></i>REACTJS</small>
                    </div>
                    <div className="text-center p-4">
                        {/* <!-- <h3 className="mb-0">$189.00</h3> --> */}
                        <div className="mb-3">
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                            <small className="fa fa-star text-primary"></small>
                        </div>
                        <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam eos</p>
                        <div className="d-flex justify-content-center mb-2">
                            <a href="#" className="btn btn-sm btn-primary px-3 border-end">Read More</a>
                            <a href="#" className="btn btn-sm btn-primary px-3" ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}
