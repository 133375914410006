import React from "react";
export default function Team(props) {
return (
    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <div className="team-item">
            <div className="overflow-hidden">'
                <img className="img-fluid" src={`../frontend/img/${props.image}`} alt=""/>
            </div>
            <div className="position-relative d-flex justify-content-center">
                <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
            </div>
            <div className="text-center p-4">
                <h5 className="mb-0">{props.name}</h5>
                <small>{props.position}</small>
            </div>
        </div>
    </div>
)
}
