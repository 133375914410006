import React from "react";
import {  useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProgressBar from "@ramonak/react-progress-bar";

const initialstate = {
    name: "",
    email: "",
    mobile: "",
    technology: "",
    message: "",
  };

  const Contact = () => {
    const notify = () => toast("Wow so easy!");
    const navigate = useNavigate();
    const [values, setValues] = useState(initialstate);
   
    const CreateData = async () => {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const formData = new FormData();
        // if (values.image !== "") {
        //   formData.append("file", values.image);
        // }
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("mobile", values.mobile);
        formData.append("technology", values.technology);
        formData.append("message", values.message);
        axios
        .post(`https://workisworkship.in/mrcreator_backend/api/contactus`, formData, config)
          .then((result) => {
            
            //console.log(result.data.message);
            toast.success(result.data.message);
            navigate("/thankyou", { state: { msg: "testing" } });
          })
          .catch((error) => {
            //alert("Data Already Exist (EMAIL / USERNAME)");
            console.log(error);
          });
      };

      const onSubmit = (e) => {
        e.preventDefault();
        const { name, email, mobile,technology,message } = values;
    
        if (!name || !email || !mobile || !technology || !message) {
          toast.error("Please fill out all fields");
          return;
        }
        CreateData(values);
      };
    
      function handleChange(e) {
          const name = e.target.name;
          const value = e.target.value;
          setValues({ ...values, [name]: value });
      }
return (

<div className="col-md-6">
    <h1 className="text-white mb-4">Get in Touch</h1>
        <ToastContainer />
    <form className="forms-sample" onSubmit={onSubmit}>

        <div className="row g-3">
            <div className="col-md-6">
                <div className="form-floating">
                    <input type="text" className="form-control bg-transparent" id="name" value={values.name}
                    onChange={handleChange} placeholder="Your Name" name="name"/>
                    <label for="name">Your Name</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating">
                    <input type="email" className="form-control bg-transparent" id="email" value={values.email}
                    onChange={handleChange} placeholder="Your Email" name="email"/>
                    <label for="email">Your Email</label>
                </div>
            </div>
            
            <div className="col-md-6">
                <div className="form-floating">
                    <input type="text" className="form-control bg-transparent" id="mobille" value={values.mobile}
                    onChange={handleChange} placeholder="Your Mobile" name="mobile"/>
                    <label for="mobile">Your Mobile</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating">
                    <select className="form-select bg-transparent" name="technology" id="select1" value={values.technology}
                    onChange={handleChange}>
                        <option value="PHP/Laravel">PHP/Laravel</option>
                        <option value="Python/Djanog">Python/Django</option>
                        <option value="Reactjs/Nodejs">Reactjs/Nodejs</option>
                        <option value="Android/Flutter">Android/Flutter</option>
                        <option value="Web Designing">Web Designing</option>
                    </select>
                    <label for="select1">Technology</label>
                </div>
            </div>
            <div className="col-12">
                <div className="form-floating">
                    <textarea className="form-control bg-transparent" value={values.message}
                    onChange={handleChange} placeholder="Special Request" id="message"  name="message"></textarea>
                    <label for="message">Special Request</label>
                </div>
            </div>
            <div className="col-12">
                <button className="btn btn-outline-light w-100 py-3" type="submit">Submit Now</button>
            </div>
        </div>
    </form> 
    {/* <ProgressBar completed={100} />;   */}
</div>
)
}

export default Contact;
