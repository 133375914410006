import { Route, Routes } from 'react-router-dom';
import React from 'react';
import Home from '../frontend/website/pages/Home';
import OurService from '../frontend/website/pages/OurService';
import Package from '../frontend/website/pages/Package';
import About from '../frontend/website/pages/About';
import Technology from '../frontend/website/pages/Technology';
import OurTeam from '../frontend/website/pages/OurTeam';
import Contact from '../frontend/website/pages/ContactUs';
import Testimonial from '../frontend/website/pages/Testimonial';
import ThankYou from '../frontend/website/pages/ThankYou';

export default function Frontend() {
  return (
<Routes>
<Route path="/">
    <Route path='/about' element={<About />} />
    <Route path='/services' element={<OurService />} />
    <Route path='/packages' element={<Package />} />
    <Route path='/popular-technology' element={<Technology />} />
    <Route path='/our-team' element={<OurTeam />} />
    <Route path='/contact' element={<Contact />} />
    <Route path='/testimonial' element={<Testimonial />} />
    <Route path='/thankyou' element={<ThankYou />} />
    <Route index path="/" element={<Home title="Search Your interested Technology" subtitle="We are providing all type websites" />} />
</Route>
</Routes>
)
}