import React from 'react';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <div class="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-4 col-md-6">
                    <h4 class="text-white mb-3">Company</h4>
                    <Link to="/about" className="btn btn-link">About</Link>
                    <Link to="/contact" className="btn btn-link">Contact</Link>
                    <Link to="/" className="btn btn-link">Privacy Policy</Link>
                    <Link to="/" className="btn btn-link">Terms & Condition</Link>
                    <Link to="/" className="btn btn-link">FAQS & Help</Link>
                </div>
                <div class="col-lg-4 col-md-6">
                    <h4 class="text-white mb-3">Contact</h4>
                    <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>Airport Road Indore</p>
                    {/* <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+91 9074900032</p> */}
                    <p class="mb-2"><i class="fa fa-envelope me-3"></i>info@mrcreator.co.in</p>
                    <div class="d-flex pt-2">
                        <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-youtube"></i></a>
                        <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                {/* <div class="col-lg-3 col-md-6">
                    <h4 class="text-white mb-3">Gallery</h4>
                    <div class="row g-2 pt-2">
                        <div class="col-4">
                            <img class="img-fluid bg-light p-1" src="../frontend/img/package-1.jpg" alt=""/>
                        </div>
                        <div class="col-4">
                            <img class="img-fluid bg-light p-1" src="../frontend/img/package-1.jpg" alt=""/>
                        </div>
                        <div class="col-4">
                            <img class="img-fluid bg-light p-1" src="../frontend/img/package-3.jpg" alt=""/>
                        </div>
                        <div class="col-4">
                            <img class="img-fluid bg-light p-1" src="../frontend/img/package-1.jpg" alt=""/>
                        </div>
                        <div class="col-4">
                            <img class="img-fluid bg-light p-1" src="../frontend/img/package-3.jpg" alt=""/>
                        </div>
                        <div class="col-4">
                            <img class="img-fluid bg-light p-1" src="../frontend/img/package-1.jpg" alt=""/>
                        </div>
                    </div>
                </div> */}
                <div class="col-lg-4 col-md-6">
                    <h4 class="text-white mb-3">Newsletter</h4>
                    <p>Feel Free to Contact Us</p>
                    <div class="position-relative mx-auto">
                        <input class="form-control border-primary w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email"/>
                        <button type="button" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="copyright">
                <div class="row">
                    <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a class="border-bottom" href="https://mrcreator.co.in/">Mrcreator</a>, All Right Reserved 2024
                        {/* <!-- Designed By <a class="border-bottom" href="javascript:;">Mrcreator</a> --> */}
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        <div class="footer-menu">
                            <a href="">Home</a>
                            <a href="">Cookies</a>
                            <a href="">Help</a>
                            <a href="">FAQS</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer;
