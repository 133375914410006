import React from 'react'
import Team from '../components/Team';

export default function OurTeam() {
  return (
    <div>
    <div class="container-fluid bg-primary py-5 mb-5 hero-header">
        <div class="container py-5">
            <div class="row justify-content-center py-5">
                <div class="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                    <h1 class="display-3 text-white animated slideInDown">Our Team</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Pages</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">Team</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div className="container-xxl py-5">
    <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">Technical Team</h6>
            <h1 className="mb-5">Meet Our Team</h1>
        </div>
        <div className="row g-4">
        <Team name="Raj Yadav" position="Web Developer" image="team_member_placeholder_image.png"/>
        <Team name="Veer Yadav" position="Web Developer" image="team_member_placeholder_image.png"/>
        <Team name="Ram Yadav" position="Web Designer" image="team_member_placeholder_image.png"/>
        <Team name="Aman Yadav" position="App Developer" image="team_member_placeholder_image.png"/>
        </div>
    </div>
    </div>
    </div>
  )
}
