import React from "react";
function Service(props) {
return (
    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
        <div className="service-item rounded pt-3">
            <div className="p-4">
                <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                <h5>{props.title}</h5>
                <p>{props.description}</p>
            </div>
        </div>
    </div>
)
}

export default Service;